import React from "react";
import StoryPage from "../../components/story-page";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/candles.jpg";
  const fullName = "Janet Snow";
  const zoomUrl = "https://us02web.zoom.us/j/123456789";
  const zoomPhoneNumber = "(123) 456-7890";
  const zoomMeetingId = "123 456 789";
  const birthDate = "January 10, 1944";
  const deathDate = "March 1, 2020";
  const memorialDate = "November 10th, 2020";
  const memorialTime = "2PM ET";
  const inviteText = "";
  const obituary = `Janet Snow died March 1, 2020, with her family by her side in Enumclaw, Wash.
  \nA memorial service was Wednesday at Greenwood Memorial Park in Renton, Wash.
  \nShe was born July 10, 1944, in Anchorage to Charles and Phoebe Smith. Her father was a Doctor in the Yukon. Her mother came up from Juneau as a Nurse.  They moved to Washington in 1946.
  \nJanet spent summers at her grandparents in remote areas of the wilderness.   She graduated from Washington High School in 1978. She attended college at the University of Washington, where she met and married Eugene Snow. After graduation, they returned to Enumclaw. Mrs. Snow was a homemaker while her husband was a Firefighter.
  \nShe was an active member of the Brighton Presbyterian Church, belonged to the Arboretum Foundation garden club and worked with various charities.
  \nHer interests included family, gardening, sewing and reading. She devoted much of her time and energy as a caregiver to family members, including her mother until she passed away at age 70.
  \nMrs. Snow is survived by her sister, Virginia Coiro; brother, Carl Snow; 2 sons and their spouses, George and Eugene Snow,  Jeff and Connie Snow; and 13 grandchildren; and 15 great-grandchildren.`;
  const storiesRecordUrl = "https://stories.afterword.com/fds8h85lm";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/rb7ipg4659mhkicei369aa34p5zd1nl7mxlbn622";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      birthDate={birthDate}
      deathDate={deathDate}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
    />
  );
};

export default ThisPage;
